import React, { useContext, useEffect, useState } from 'react'
import Notiflix from 'notiflix'
import UserPanelLayout from '../components/UserPanelLayout'
import { AuthContext } from '../components/Auth'
import '../assets/css/pages/Ajustes.css'
import Modal from '../components/Modal'
import { database } from '../firebaseConfig'
const Ajustes = () => {

  
    const { currentUser } = useContext(AuthContext)
    const [openModal, setOpenModal] = useState(false)
    const [datosRestauruante, setDatosRestaurante] = useState({})
    const [inputNombre,setNombreInp] = useState("")

    useEffect(()=>{
        document.title = 'GÜER - Ajustes de negocio'
		Notiflix.Notify.Init({ position: 'right-bottom' })
        getDatos()
       
    })

    const getDatos = async () => {
        try {
          const res =  await database.collection('restaurante').where('idUser', '==', currentUser.uid)
          .onSnapshot((querysnapshot)=>{
            querysnapshot.forEach((doc) => {
                setDatosRestaurante(doc.data())
                
            })
          })
        
        } catch (error) {
            Notiflix.Notify.Failure(
				'Ocurrió un error al traer la información. Por favor inténtalo de nuevo.'
			)
        }
    }

    const guardarNombre = () =>{
        const nuevosDatos = datosRestauruante
        nuevosDatos.nombre = inputNombre
        console.log(nuevosDatos)
        try {
            database.collection('restaurante').doc(nuevosDatos).update(nuevosDatos)
            Notiflix.Notify.Success('Se actualizo la comida correctamente.')
        } catch (error) {
            Notiflix.Notify.Failure(
				'Ocurrió un error al guardar la información. Por favor inténtalo de nuevo.'
			)
        }
    }

    if (currentUser) {
    return(
        <UserPanelLayout title="Ajustes">
            { openModal && (
                <Modal
                    closeModal={()=>setOpenModal(false)}
                >
                    <p>Cambiar nombre de restaurante</p>
                    <input type="text" placeholder='Introduce el nombre de tu restaurante' value={inputNombre} onChange={(event)=>{setNombreInp(event.target.value)}} />
                    <button onClick={guardarNombre}>Cambiar</button>
                </Modal>
            )}
            { 
                datosRestauruante && 
                (
                <div>
                    <div className='contenedor__ajuste '>
                        <div>
                            <p>Nombre de la tienda.</p>
                            <h1>{currentUser.displayName}</h1>
                        </div>
                        <div className='ajuste__acciones'>
                            <button 
                            onClick={()=>setOpenModal(true)}
                            >
                            Editar
                            </button>
                        </div>
                    </div>
                    <div className='contenedor__ajuste '>
                        <div>
                            <p>Correo</p>
                            <h1>{currentUser.email}</h1>
                        </div>
                        <div className='ajuste__acciones'>
                            <button>
                            Editar
                            </button>
                        </div>
                    </div>
                </div>
                )}
        </UserPanelLayout>
    )
    }
}

export default Ajustes