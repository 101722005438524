import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const app = firebase.initializeApp({
	databaseURL: 'https://menu-digital-bc77f.firebaseio.com',
	apiKey: "AIzaSyDUcOkiCpvckOmJGB_1x918dyiS_AHKcqc",
  authDomain: "menusdigitales-1a3e2.firebaseapp.com",
  projectId: "menusdigitales-1a3e2",
  storageBucket: "menusdigitales-1a3e2.appspot.com",
  messagingSenderId: "972391886091",
  appId: "1:972391886091:web:662b91d8bee6eab971c60e",
  measurementId: "G-WHE315GVKQ"
})

export const database = app.firestore()

export default app
